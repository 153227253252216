<template>
  <v-card class="ma-0 pa-0 elevation-0 white">
    <div class="my-border">
      <v-card-title class="py-0 my-0 customlightgray">
        <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{tableTitle}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                            class="custom-color-accent pa-1">{{documents.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                   <template v-slot:prepend-inner>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                        </template>
                        <span>{{$t("advanced")}}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" v-if="request!=null" depressed :to="{ name: 'editor', params: { request_id: request.id } }" target="_blank">{{$t("openeditor")}}</v-btn>
            </v-col>
        </v-row>
      </v-card-title>
      <!-- dialog advanced search -->
      <v-dialog v-model="dialog_adv" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t("advanced")}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-row class="ma-0">
               <v-col cols="12" class="ma-0 py-0"> 
                  <v-text-field class="ma-0" :label="$t('contactname')" :placeholder="$t('contactname')" dense outlined v-model="filters.contact_name"></v-text-field>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    small-chips
                    multiple
                    clearable
                    v-model="filters.req_code"
                    :items="requests.map(request=>{return {value:request.reqCode,text:request.reqCode}})"
                    :label="$t('requestid')"
                    :placeholder="$t('requestid')"
                    dense
                    outlined
                    return-object
                  ></v-autocomplete>
               </v-col>
               <v-col cols="12" class="ma-0 py-0">  
                  <v-text-field class="ma-0" :label="$t('documentfilename')" :placeholder="$t('documentfilename')" dense outlined v-model="filters.doc_file_name"></v-text-field>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.created_at" 
                        multiple
                        chips
                        dense
                        clearable
                        outlined
                        small-chips
                        :label="$t('datecreated')"
                        :placeholder="$t('datecreated')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.created_at" :max="nowDate"></v-date-picker> 
                  </v-menu>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.sent_out_date"
                        multiple
                        chips
                        dense
                        clearable
                        outlined
                        small-chips
                        :label="$t('datesent')"
                        :placeholder="$t('datesent')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.sent_out_date" :max="nowDate"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 py-0">
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialog_adv = false">{{$t("close")}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="findConsultations">{{$t("search")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- /dialog advanced search -->
     
      <!-- Filters -->    
      <v-divider  v-show="filterList.length>0"></v-divider>       
      <v-row class="pa-0 ma-0 white" v-show="filterList.length>0">
        <v-col class="pa-0 ma-0" cols="12">
          <v-scroll-x-transition group hide-on-leave>
          <v-tooltip v-for="(f, i) in filterList" :key="i" right>
            <template v-slot:activator="{ on, attrs }">
              <v-chip  v-on='on' v-bind="attrs"
                class="ma-1"
                color="custom-color-accent"
                close
                small
                close-icon="mdi-delete"
                @click:close="removeFilter(f)" >
                  {{f}}
                </v-chip>
              </template>
              <span>{{$t("removefilter")}}</span>
            </v-tooltip>
          </v-scroll-x-transition>
        </v-col>
      </v-row> 
      <!-- /Filters -->
      <v-divider></v-divider>
      <v-data-table :mobile-breakpoint="1081" 
        v-if="documents"
        :items="documents"
        :headers="headers"
        sort-by="id"
        class="elevation-0 pa-0 ma-0 text--secondary customoffwhite"
        :search="search"
        :loading-text="$t('Loading')"
        :footer-props="{
          'items-per-page-text': $t('rowsperpage'),
        }"
      >
        <template v-slot:no-data> {{ $t("noDataAvailable") }}</template>
        <template v-slot:[`item.req_code`]="{ item }">
          <v-chip @click="viewRequest(item.req_code)"
            class="pa-0 ma-0 mx-1 justify-center status-chip"
            small
            color='custom-color-accent'>
              {{ item.req_code }}
          </v-chip>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <div>{{ formatDate(item.created_at) }}</div>
        </template>
        <template v-slot:[`item.sent_out_date`]="{ item }">
          <div>{{ formatDate(item.sent_out_date) }}</div>
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Documents.Read')">
                    <v-icon
                      @click="getConsultDoc(item)">
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("download")}}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Documents.Read')">
                    <v-icon
                      @click="sendmail(item)">
                      mdi-email
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("send")}}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Documents.Delete')">
                    <v-icon
                      @click="deleteConsult(item.id)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import axcessApi from "@/plugins/axcessApi";
export default {
  props: ["request","documents"],
  data() {
    return {
      search: "",
      dialog: false,
      selected: [],
      dialog_adv: false,
      nowDate: new moment().toISOString(),
      cu_req:{},
      filterList:[],
      headers: [
        { text: this.$t("requestid"), value: "req_code" },

        { text: this.$t("filename"), value: "doc_file_name" },
        { text: this.$t("datecreated"), value: "created_at" },
        { text: this.$t("datesent"), value: "sent_out_date" },
        { text: this.$t("contactname"), value: "contact_name" },
        { text: "", value: "action", sortable: false },
      ],
      address: [],
      editedIndex: -1,
      filters: {
        doc_file_name: "",
        contact_name: "",
        created_at:[],
        sent_out_date:[],
        req_code:[],
      },
    };
  },
  created() {
      this.retriveRequests();
      Object.assign(this.filters, this.storeFilters);
      Object.assign(this.cu_req, this.request);
      this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
      this.filterList = this.filterList.filter(el=> el != "");
  },
  computed: {
    ...mapState("consultation", {
      loading: "loading",storeFilters:"filter"
    }),
    ...mapState("request", {
      requests: "requests"
    }),
    tableTitle() {
      return this.$t("Consultations");
    },
  },
  watch:{
    storeFilters: {
      handler(){
          Object.assign(this.filters, this.storeFilters);  
          this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
                this.filterList = this.filterList.filter(el=> el != "");

      }, deep: true 
    },
  },
  methods: {

    viewRequest(req_code){
      let req=this.requests.filter(item=>item.req_code==req_code);
      if(req.length==0)
        alert(this.$t('request not found'))
      else
      this.$router.push({name: 'requests',params: { id: req.id }})
    },
    getConsultDoc(item) {
        return new Promise((resolve, reject) => {
          axcessApi
            .getRequest("/consultdoc/" + item.request_id + "/docid/" + item.doc_file_name, {
              responseType: "arraybuffer",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            })
            .then((res) => {
              let blob = new Blob([res.data], { type: "application/*" });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = item.doc_file_name;
              link._target = "blank";
              link.click();
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
    },
    findConsultations(){
      this.close();
      this.filterConsultations(this.filters);
    },
    removeFilter(item){
      Object.keys(this.filters).forEach((key)=>{
      if( !Array.isArray(this.filters[key]) && (this.filters[key].text==item))
        this.filters[key]={text:"",value:""};        
      else if( !Array.isArray(this.filters[key]) && (this.filters[key]==item))
        this.filters[key]="";           

      else if(Array.isArray(this.filters[key]) && this.filters[key].map(a=>a.text).includes(item))
      this.filters[key]= this.filters[key].filter(a=>a.text!=item);

      else if(Array.isArray(this.filters[key]) && this.filters[key].includes(item))
      this.filters[key]= this.filters[key].filter(a=>a!=item);
      });
      this.filterConsultations(this.filters);
    }, 
    close() {
      this.dialog = false;
      this.dialog_adv = false;
      this.editedIndex = -1;
      this.cu_req={}
      Object.assign(this.cu_req, this.request);
    },
    formatDate(value) {
      return value != null ? moment(value).format("YYYY-MM-DD") : "";
    },
    deleteConsult(id) {
      if (confirm(this.$t("Are you sure you want to delete this item?"))) {
        this.deleteReqConsult(id);
      }
    },
    async sendmail(item) {
      await this.updateSentdate(item.id);
      let url = this.generateUuid(item);
      url.then((result) => {
        const response = axcessApi.getRequest("/address/" + item.agency_id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        response.then((output) => {
          let link =
            "mailto:" +
            output.data.Email +
            "?subject=" +
            encodeURIComponent("Request " + item.req_code + " Consultation") +
            "&body=" +
            encodeURIComponent(
              "Good Day, " +
                item.contact_name +
                " You can download consultation file by clicking on the following link: " +
                result.data
            );
          // + "?cc=myCCaddress@example.com"
          window.location.href = link;
        });
      });
    },
    ...mapActions("consultation", [
      "deleteReqConsult",
      "updateSentdate",
      "filterConsultations",
    ]),
    ...mapActions("address", ["generateUuid"]),
    ...mapActions("request", ["retriveRequests"]),
  },
};
</script>
<style scoped>
.status-chip {
  width: 130px;
}
</style>