<template>
  <div>
    <consultations :request="null" :documents="getConsultDoc"></consultations>
  </div>
 
</template>
<script>

import {mapActions, mapGetters, mapState} from "vuex";
import consultations from "@/components/consultation/ConsultationTable";
export default {
  components: {
    consultations
  },
 data() {
    return {
      consultationFilters:{},
    };
  },
 async created() {
    Object.assign(this.consultationFilters, this.storeConsultationFilters);
    this.filterConsultations(this.consultationFilters);
    this.clearConsultationsFilter();
    await this.retrieveConsultations();
    await this.retriveRequests();
  },

  computed: {
    ...mapGetters('pcoconsultation', {
      'PCOFiltered':'PCOFiltered'
    }),
     ...mapState('consultation', {
      'storeConsultationFilters':'filter'
    }),
    getConsultDoc() {
      return this.$store.getters['consultation/getConsultDoc'](null)
    },
  },
  watch: {
    getConsultDoc: {
      handler(){
        Object.assign(this.consultationFilters, this.storeConsultationFilters);
      }, deep: true 
    }
  },
  methods: {
    ...mapActions('pcoconsultation',['retrievePCOconsultation','filterPCOconsultations','clearPCOconsultationsFilter']),
    ...mapActions('consultation',['retrieveConsultations','filterConsultations','clearConsultationsFilter']),
    ...mapActions("request", ["setRequestItem", "retriveRequestItem","retriveRequests"]),
  }

};
</script>